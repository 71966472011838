<template>
    <div
        v-if="!!sourceAccount"
        :class="[isLoading ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="dirtyAccount"
            #default="context"
            :actions="false"
            :config="{ validationVisibility: 'blur' }"
        >
            <FormKit
                type="text"
                name="name"
                label="* Name"
                validation="required"
                :disabled="!isEditing"
                :innerClass="'$reset w-full border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500'"
            />
            <FormSection>
                <template #default>
                    <FormKit
                        type="select"
                        label="Time Zone"
                        name="timezone"
                        :options="timezoneOptions"
                        outerClass="col-span-4 sm:col-span-1 sm:min-w-max"
                        :validation-rules="{ timezone }"
                        :validation="[
                            ['required'],
                            ['*+not', 'placeholder'],
                            ['timezone'],
                        ]"
                        :disabled="!isEditing"
                        validation-visibility="live"
                    />
                    <FormKit
                        type="select"
                        label="Temperature Scale"
                        name="tempScale"
                        outerClass="col-start-1 col-span-4 sm:col-start-3 sm:col-span-1 sm:min-w-max"
                        :validation="[['required'], ['*+not', 'placeholder']]"
                        :disabled="!isEditing"
                        :options="temperatureOptions"
                    />
                </template>
            </FormSection>
            <HierarchyFormSection>
                <template #title>Manage Hierarchy</template>
                <template #description>
                    <p>
                        NARA uses consistent terminology and abbreviations for
                        choices in the four Location Hierarchy levels.
                        <span class="font-bold"
                            >Level 1- Building. Level 2-Administration</span
                        >
                        (AO-Archives Office. FRC-Records Center.
                        PLA-Presidential Library Archives. PLM-Presidential
                        Library Museum).
                        <span class="font-bold">Level 3-Space Type</span>
                        (ST-Storage. WR-Workroom. EX-Exhibition. RR-Research
                        Room).
                        <span class="font-bold">Level 4-Monitoring Site</span>
                        (space name/number or a custom identifier).
                    </p>
                </template>
                <template #default>
                    <FormKit
                        type="list"
                        name="accountTreeLevel"
                    >
                        <FormKit
                            type="text"
                            label="Level 1"
                            name="site"
                            value="Site"
                            :disabled="!isEditing"
                            validation="required:trim|length:1"
                            outerClass="pr-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 2"
                            name="building"
                            value="Building"
                            :disabled="!isEditing"
                            validation="required:trim|length:1"
                            outerClass="pr-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 3"
                            name="floor"
                            value="Floor"
                            :disabled="!isEditing"
                            validation="required:trim|length:1"
                            outerClass="pr-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 4"
                            name="room"
                            value="Room"
                            :disabled="!isEditing"
                            validation="required:trim|length:1"
                        />
                    </FormKit>
                </template>
            </HierarchyFormSection>
            <p class="text-gray-400 text-sm">* indicates a required field</p>
            <FormSubmitCancel
                :allowSave="context.state.valid && !isLoading"
                :onSave="onClickSave"
                :onCancel="onClickCancel"
            />
        </FormKit>
        <div
            v-if="!!debug"
            class="mt-6"
        >
            <DebugFrame
                id="generic"
                :startHidden="frame.startHidden"
                :debug="frame.isEnabled"
                :data="frame.data"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, ref, onBeforeMount } from 'vue';

    // <!-- UTILITIES -->
    import clone from 'just-clone';

    // <!-- COMPONENTS -->
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import HierarchyFormSection from '@/components/forms/sections/HierarchyFormSection.vue';
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';

    // <!-- TYPES -->
    /** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
    /** @typedef {import('@/features/account-manager/hooks/useAccountManager').IAccountTarget} IAccountTarget */

    /** @typedef {import('@formkit/core').FormKitNode} FormKitNode */

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import {
        useTimezoneOptions,
        getLegacyTimezones,
    } from '~DataManager/hooks/useTimezoneOptions';
    import { useTemperatureOptions } from '~DataManager/hooks/useTemperatureOptions';
    import { useReminderOptions } from '~DataManager/hooks/useReminderOptions';
    import is from '@sindresorhus/is';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AccountFields',
        components: {
            FormSection,
            HierarchyFormSection,
            FormSubmitCancel,
            DebugFrame,
        },
        props: {
            sourceAccount: {
                /** @type {V.PropType<Omit<IAccountTarget, 'id'> & Partial<Pick<IAccountTarget, 'id'>>>} */
                type: Object,
            },
            isEditing: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            onCancel: {
                /** @type {V.PropType<() => void>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            onSave: {
                /** @type {V.PropType<(account: Omit<IAccountTarget, 'id'> & Partial<Pick<IAccountTarget, 'id'>>) => Promise<void>>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            debug: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        setup(props, context) {
            // ==== STATE ====
            /** @type {V.Ref<Omit<IAccountTarget, 'id'> & Partial<Pick<IAccountTarget, 'id'>>>} */
            const dirtyAccount = ref(null);

            // ==== EVENTS ====

            /** Clone the clean account. */
            const resetDirtyAccount = () => {
                const instance = clone({ ...props.sourceAccount });
                const useExisting =
                    !is.nullOrUndefined(instance?.accountTreeLevel) &&
                    is.iterable(instance?.accountTreeLevel);
                instance.accountTreeLevel = useExisting
                    ? [...instance.accountTreeLevel]
                    : ['Site', 'Building', 'Floor', 'Room'];
                dirtyAccount.value = instance;
            };

            /** Save the dirty account. */
            const onClickSave = async () => {
                await props.onSave(dirtyAccount.value);
            };

            /** Clear the dirty account. */
            const onClickCancel = () => {
                resetDirtyAccount();
                props.onCancel();
            };

            /* Data */
            const timezoneOptions = useTimezoneOptions();
            const legacyTimezones = getLegacyTimezones();
            const reminderOptions = useReminderOptions();
            const temperatureOptions = useTemperatureOptions();

            /**
             * Custom validation rule that ensures legacy timezones are not included.
             * @param {FormKitNode} node
             */
            const timezone = (node) => {
                const value = String(node?.value ?? 'placeholder');
                const invalid = new Set(['placeholder', ...legacyTimezones]);
                return !invalid.has(value);
            };

            // ==== DEBUG ====
            /**
             * Computed debug frame.
             */
            const frame = computed(() => {
                // Prepare data.
                const data = [
                    DebugObject.create(`Is Editing?`, {
                        isEditing: props.isEditing,
                    }),
                    DebugObject.create(`Is Loading?`, {
                        isLoading: props.isLoading,
                    }),
                    DebugObject.create(`Source Account Details`, {
                        sourceAccount: props.sourceAccount,
                    }),
                    DebugObject.create(`Dirty Account`, {
                        dirtyAccount: dirtyAccount.value,
                    }),
                ];
                // Return new frame instance.
                return useDebugFrame({
                    isEnabled: true,
                    startHidden: true,
                    data,
                });
            });

            // ==== LIFECYCLE ====
            onBeforeMount(() => {
                resetDirtyAccount();
            });

            // ==== EXPOSE ====
            return {
                frame,
                dirtyAccount,
                timezoneOptions,
                legacyTimezones,
                reminderOptions,
                temperatureOptions,
                timezone,
                onClickSave,
                onClickCancel,
            };
        },
    });
</script>

<style lang="scss">
    .account-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
