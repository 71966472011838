// <!-- API -->
import { ref, computed } from 'vue';
import {
    createAccount,
    updateAccountById,
    deleteAccountById,
    fetchAccountById,
} from '@/api/accounts';

// <!-- COMPONENTS -->
import AccountManagerTableIcons from '~AccountManager/components/AccountManagerTableIcons.vue';

// <!-- UTILITIES -->
import clone from 'just-clone';
import pick from 'just-pick';
import omit from 'just-omit';
import isNil from 'lodash-es/isNil';
import { formatISO } from 'date-fns';
import { DateTimeISO } from '@/utils/datetime';
import { Reminder } from '@/utils/enums';
import { isEnumValue } from '@/utils/typeof';
import is from '@sindresorhus/is';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import { useAccountsIndex } from '@/hooks/cache/useAccountsIndex';
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */

/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */

/** @template [T=any] @typedef {{ -readonly [P in keyof T]: T[P] }} Writeable */

/** @typedef {Pick<AccountResource, 'id' | 'name' | 'reminder' | 'tempScale' | 'timezone' | 'accountTreeLevel'>} IAccountTarget */
/** @typedef {import('@/api/accounts').ICreateAccountRequest} ICreateAccountRequest */
/** @typedef {import('@/api/accounts').IEditAccountRequest} IEditAccountRequest */

// <!-- COMPOSABLE -->
/**
 * Provides access to all composable submodules.
 */
class AccountManager {
    /**
     * Instantiate a new AccountManager composable.
     * @param {Object} [props] Props to pass to the AccountManager.
     * @param {Store<ECNBState>} [props.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [props.alerts] Alerts composable.
     * @param {ReturnType<useAgGridVue>} [props.grid] AgGrid composable.
     * @param {ReturnType<useAccountsIndex>} [props.accounts] Account index composable.
     */
    constructor(props = {}) {
        // Deconstruct parameters.
        const { store, alerts, grid, accounts } = props ?? {};

        /** @type {Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAgGridVue>} */
        this.grid = grid ?? useAgGridVue();

        /** @type {ReturnType<useAccountsIndex>} */
        this.accounts = accounts ?? useAccountsIndex();

        /** @type {AccountManagerConstants} */
        this.constants = new AccountManagerConstants(this);

        /** @type {AccountManagerState} */
        this.state = new AccountManagerState(this);

        /** @type {AccountManagerCache} */
        this.cached = new AccountManagerCache(this);

        /** @type {AccountManagerAPI} */
        this.api = new AccountManagerAPI(this);

        /** @type {Boolean} */
        this.initialized = false;
    }

    /**
     * Initialize respective submodule
     */
    initialize() {
        const $context = this;
        if (!$context.initialized) {
            // Initialize sequentially. These must be synchronous.
            $context.constants.initialize();
            $context.state.initialize();
            $context.cached.initialize();
            $context.api.initialize();
            $context.initialized = true;
            // If an onInit event exists, invoke it now.
            $context.api.events?.onInit?.();
            // Return the context.
            return $context;
        }
    }

    /**
     * Get access to the module setters.
     */
    get register() {
        const $context = this;
        return {
            /** @param {AccountManager['constants']} instance */
            constants: (instance) => {
                $context.constants = instance;
                return $context;
            },
            /** @param {AccountManager['state']} instance */
            state: (instance) => {
                $context.state = instance;
                return $context;
            },
            /** @param {AccountManager['cached']} instance */
            cached: (instance) => {
                $context.cached = instance;
                return $context;
            },
            /** @param {AccountManager['api']} instance */
            api: (instance) => {
                $context.api = instance;
                return $context;
            },
        };
    }

    /**
     * Get reactive data and computed properties.
     * @returns {Omit<AccountManagerConstants, 'initialize'> & Omit<AccountManagerState, 'initialize'> & Omit<AccountManagerCache, 'initialize' | 'initStatusConditionals' | 'initOpenModalConditionals' | 'initAccountTargets'>}
     */
    get data() {
        const $context = this;
        return {
            ...$context.constants,
            ...$context.state,
            ...$context.cached,
        };
    }

    /**
     * Get the actions.
     * @returns {AccountManagerAPI['events'] & AccountManagerAPI['methods']}
     */
    get actions() {
        const $context = this;
        return {
            ...$context.api.events,
            ...$context.api.methods,
        };
    }
}

// ==== CONSTANTS ====
/**
 * @class
 * Submodule for the {@link AccountManager} composable.
 */
class AccountManagerConstants {
    /**
     * Instantiate submodule.
     * @param {AccountManager} context
     */
    constructor(context) {
        /** @type {AccountManager} */
        this.context = context;
        this.context.register.constants(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        /** Loading status IDs. */
        this.LoadingIDs = /** @type {const} */ ([
            'idle',
            'loading',
            'success',
            'failure',
        ]);
        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ([
            'addAccount',
            'editAccount',
            'viewAccount',
            'confirmDelete',
        ]);
        /** @type {IAccountTarget} */
        this.DefaultAccountTarget = {
            id: null,
            name: '',
            reminder: 'never',
            tempScale: 'F',
            timezone: 'America/New_York',
            accountTreeLevel: [
                'Building',
                'Administration',
                'Space Type',
                'Monitoring Site',
            ],
        };
    }
}

// ==== STATE ====
/**
 * @class
 * Submodule for the {@link AccountManager} composable.
 */
class AccountManagerState {
    /**
     * Instantiate submodule.
     * @param {AccountManager} context
     */
    constructor(context) {
        /** @type {AccountManager} */
        this.context = context;
        this.context.register.state(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== STATUS ====
        /** @type {V.Ref<'idle' | 'loading' | 'success' | 'failure'>} */
        this.status = ref('idle');

        // ==== OPEN MODALS ====
        /** @type {V.Ref<'addAccount' | 'editAccount' | 'confirmDelete'>} Only one modal can be open at a time. */
        this.openModal = ref(null);

        // ==== ACCOUNT INDEX ====
        /** @type {V.Ref<Map<Number, AccountResource>>} */
        this.accountIndex = ref(new Map());

        // ==== ACCOUNT TARGETS ====
        /** @type {V.Ref<Omit<IAccountTarget, 'id'>>} Account target. */
        this.accountToAdd = ref(null);

        /** @type {V.Ref<IAccountTarget>} Account target. */
        this.accountToEdit = ref(null);

        /** @type {V.Ref<Pick<IAccountTarget, 'id' | 'name'>>} Account target. */
        this.accountToDelete = ref(null);

        // ==== AG GRID ====
        /** @type {V.Ref<Array<AccountResource>>} */
        this.rowData = ref([]);

        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.colDefs = ref([]);
    }
}

// ==== COMPUTED PROPERTIES ====
/**
 * @class
 * Submodule for the {@link AccountManager} composable.
 */
class AccountManagerCache {
    /**
     * Instantiate submodule containing computed properties.
     * @param {AccountManager} context
     */
    constructor(context) {
        /** @type {AccountManager} */
        this.context = context;
        this.context.register.cached(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== CONDITIONALS (STATUS) ====
        this.initStatusConditionals();
        // ==== CONDITIONALS (MODALS) ====
        this.initOpenModalConditionals();
    }

    /**
     * Initialize the status conditionals.
     */
    initStatusConditionals() {
        const { state } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isIdle = computed(() => {
            return state.status.value === 'idle';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoading = computed(() => {
            return (
                state.status.value === 'loading' ||
                this.context.accounts.isFetching.value === true
            );
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithSuccess = computed(() => {
            return state.status.value === 'success';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithFailure = computed(() => {
            return state.status.value === 'failure';
        });
    }

    /**
     * Initialize the open modal conditionals.
     */
    initOpenModalConditionals() {
        const { state } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isAddAccountModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'addAccount';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isEditAccountModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'editAccount';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isConfirmDeleteModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'confirmDelete';
        });
    }
}

class AccountManagerAPI {
    /**
     * Instantiate submodule containing computed properties.
     * @param {AccountManager} context
     */
    constructor(context) {
        /** @type {AccountManager} */
        this.context = context;
        this.context.register.api(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== GETTERS ====
        this.initGetters();
        // ==== SETTERS ====
        this.initSetters();
        // ==== METHODS ====
        this.initMethods();
        // ==== EVENTS ====
        this.initEventHandlers();
    }

    initGetters() {
        const $api = this;
        const { state, store } = $api.context;

        /**
         * Get keyed column definitions.
         * @returns {Readonly<Record<String, AgGrid.ColumnDef>>}
         */
        const getColumnSchema = () => {
            return {
                /** @type {AgGrid.ColumnDef} Table icons with button actions. */
                icons: {
                    headerName: '',
                    field: 'actions',
                    cellRendererFramework: AccountManagerTableIcons,
                    lockPosition: true,
                    filter: false,
                    maxWidth: 120,
                    cellRendererParams: {
                        /**
                         * Handle editing of a account.
                         * @param {Object} event
                         * @param {Number} index Account index.
                         */
                        handleEdit: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const account = state.accountIndex.value.get(id);
                            $api.events.onClick.editAccount(account);
                        },
                        /**
                         * Handle deletion of a account.
                         * @param {Object} event
                         * @param {Number} index Account index.
                         */
                        handleDelete: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const account = state.accountIndex.value.get(id);
                            $api.events.onClick.deleteAccount(account);
                        },
                    },
                },
                id: {
                    headerName: 'Account ID',
                    field: 'id',
                    maxWidth: 200,
                },
                name: {
                    headerName: 'Account Name',
                    field: 'name',
                    minWidth: 130,
                    //sort: 'desc',
                    comparator: (valueA, valueB) => {
                        const valueALower = valueA.toLowerCase().trim();
                        const valueBLower = valueB.toLowerCase().trim();
                        return valueALower.localeCompare(valueBLower, 'en');
                    },
                    autoHeight: true,
                },
                lastUploadDate: {
                    headerName: 'Last Upload',
                    field: 'lastUploadDate',
                    valueFormatter: $api.methods.formatDate,
                    minWidth: 100,
                    sort: 'desc',
                },
            };
        };

        /**
         * Get column definitions in ordered array.
         * @returns {AgGrid.ColumnDef[]}
         */
        const getColumnDefs = () => {
            const schema = getColumnSchema();
            return [schema.icons, schema.name, schema.lastUploadDate];
        };

        /**
         * Get the currently selected account resource from the store.
         * @returns {AccountResource}
         */
        const getSelectedAccount = () => {
            return store.state.accounts.account;
        };

        /**
         * Create account index from array,
         * @param {AccountResource[]} accounts
         * @returns {Map<Number, AccountResource>}
         */
        const getAccountsAsIndex = (accounts) => {
            /** @type {[ id: Number, account: AccountResource ][]} */
            const entries = accounts.map((a) => {
                /** @type {[ id: Number, account: AccountResource ]} */
                const entry = [a.id, { ...a }];
                return entry;
            });
            /** Get map. */
            return new Map(entries);
        };

        /**
         * Create row data from array of accounts.
         * @param {Readonly<AccountResource[]>} accounts
         * @returns {AccountResource[]}
         */
        const getAccountsAsRowData = (accounts) => {
            return accounts.map((account) => getAccountAsRecord(account));
        };

        /**
         * Clone the account resource.
         * @param {Readonly<AccountResource>} account
         * @returns {AccountResource}
         */
        const getAccountAsRecord = (account) => {
            const instance = { ...account };
            instance.accountTreeLevel = clone(account.accountTreeLevel);
            instance.level = clone(account.level);
            return instance;
        };

        /**
         * Copy account from index as a selected account target.
         * @param {AccountResource['id']} id
         * @returns {IAccountTarget}
         */
        const getAccountAsTarget = (id) => {
            const source = state.accountIndex.value.get(id);
            const target = clone(
                pick(
                    source,
                    'id',
                    'name',
                    'reminder',
                    'tempScale',
                    'timezone',
                    'accountTreeLevel'
                )
            );
            return target;
        };

        /**
         * Sanitize the account resource as a valid request.
         * @param {Required<Pick<ICreateAccountRequest, 'name' | 'timezone' | 'temp_scale' | 'account_tree_level'>> & Partial<ICreateAccountRequest>} source
         * @return {Required<ICreateAccountRequest>}
         */
        const getSanitizedCreateRequest = (source) => {
            /** @type {Required<Writeable<ICreateAccountRequest>>} */
            const request = Object.assign(
                clone(this.context.constants.DefaultAccountTarget),
                source
            );

            // Sanitize the reminder field.
            if (!isEnumValue(Reminder, request.reminder)) {
                request.reminder = Reminder['Not Now'];
            }

            // Send back the sanitized request.
            return Object.freeze(request);
        };

        /**
         * Sanitize the account resource as a valid request.
         * @param {Required<Pick<IEditAccountRequest, 'name' | 'timezone' | 'temp_scale' | 'account_tree_level'>> & Partial<IEditAccountRequest>} source
         * @return {Required<IEditAccountRequest>}
         */
        const getSanitizedEditRequest = (source) => {
            /** @template [T=any] @typedef {{ -readonly [P in keyof T]: T[P] }} Writeable */
            /** @type {Required<Writeable<IEditAccountRequest>>} */
            const request = Object.assign(
                clone(this.context.constants.DefaultAccountTarget),
                source
            );

            // Sanitize the reminder field.
            if (!isEnumValue(Reminder, request.reminder)) {
                request.reminder = Reminder['Not Now'];
            }

            // Send back the sanitized request.
            return Object.freeze(request);
        };

        /** Getter calls that provide live accessors. */
        this.getters = {
            getColumnSchema,
            getColumnDefs,
            getSelectedAccount,
            getAccountsAsIndex,
            getAccountsAsRowData,
            getAccountAsRecord,
            getAccountAsTarget,
            getSanitizedCreateRequest,
            getSanitizedEditRequest,
        };
    }

    initSetters() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Set the loading status.
         * @param {'idle' | 'loading' | 'success' | 'failure'} [id]
         */
        const setLoading = (id = 'idle') => {
            state.status.value = id ?? 'idle';
        };

        /**
         * Set the open modal.
         * @param {'addAccount' | 'editAccount' | 'confirmDelete'} id
         */
        const setOpenModal = (id = null) => {
            state.openModal.value = id ?? null;
        };

        /**
         * Set account index instance.
         * @param {Map<Number, AccountResource>} index
         */
        const setAccountIndex = (index) => {
            state.accountIndex.value = new Map(index.entries());
        };

        /**
         * Set account target for new account.
         * @param {Readonly<Partial<Omit<IAccountTarget, 'id'>>>} target
         */
        const setAddAccountTarget = (target) => {
            if (!isNil(target)) {
                // If target is defined, clone the default account target to get placeholders.
                const clean = this.context.constants.DefaultAccountTarget;
                const instance = omit(Object.assign({}, clean, target), 'id');
                state.accountToAdd.value = instance;
            } else {
                // Otherwise, set the null value.
                state.accountToAdd.value = null;
            }
        };

        /**
         * Set account target for the corresponding id.
         * @param {AccountResource['id']} id
         */
        const setEditAccountTarget = (id) => {
            if (!isNil(id)) {
                // If id is defined, find the matching target (if possible).
                const { getAccountAsTarget } = $api.getters;
                const target = id ? getAccountAsTarget(id) : null;
                state.accountToEdit.value = target;
            } else {
                // Otherwise, set the null value.
                state.accountToEdit.value = null;
            }
        };

        /**
         * Set account target for the corresponding id.
         * @param {AccountResource['id']} id
         */
        const setDeleteAccountTarget = (id) => {
            if (!isNil(id)) {
                // If id is defined, find the matching target (if possible).
                const { getAccountAsTarget } = $api.getters;
                const target = id ? getAccountAsTarget(id) : null;
                state.accountToDelete.value = target;
            } else {
                // Otherwise, set the null value.
                state.accountToDelete.value = null;
            }
        };

        /**
         * Set the row data.
         * @param {AccountResource[]} data
         */
        const setRowData = (data) => {
            state.rowData.value = [...data];
        };

        /** Setters for mutation of the state. */
        this.setters = {
            setLoading,
            setOpenModal,
            setAccountIndex,
            setRowData,
            get setAccountTarget() {
                return {
                    toAdd: setAddAccountTarget,
                    toEdit: setEditAccountTarget,
                    toDelete: setDeleteAccountTarget,
                };
            },
        };
    }

    initEventHandlers() {
        const $api = this;
        const { state, alerts } = $api.context;
        /**
         * When accounts index is loaded/refreshed,
         * update the row data
         * @param {AccountResource[]} accounts
         */
        const onUpdateAccounts = (accounts) => {
            const { getAccountsAsIndex, getAccountsAsRowData } = $api.getters;
            const { setAccountIndex, setRowData } = $api.setters;
            const accountIndex = getAccountsAsIndex(accounts);
            const accountData = getAccountsAsRowData(accounts);
            setAccountIndex(accountIndex);
            setRowData(accountData);
        };

        /**
         * On click add account.
         */
        const onClickAddAccount = () => {
            const { open } = $api.methods;
            const { setAccountTarget } = $api.setters;
            setAccountTarget.toAdd({ name: '' });
            open.addAccountModal();
        };

        /**
         * On click edit account.
         * @param {AccountResource} target
         */
        const onClickEditAccount = (target) => {
            const { open } = $api.methods;
            const { setAccountTarget } = $api.setters;
            setAccountTarget.toEdit(target.id);
            open.editAccountModal();
        };

        /**
         * On click delete account.
         * @param {AccountResource} target
         */
        const onClickDeleteAccount = (target) => {
            const { open } = $api.methods;
            const { setAccountTarget } = $api.setters;
            setAccountTarget.toDelete(target.id);
            open.confirmDeleteModal();
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelAddAccount = () => {
            const { close } = $api.methods;
            const { setAccountTarget } = $api.setters;
            close.addAccountModal();
            setAccountTarget.toAdd(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelEditAccount = () => {
            const { close } = $api.methods;
            const { setAccountTarget } = $api.setters;
            close.editAccountModal();
            setAccountTarget.toEdit(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelDeleteAccount = () => {
            const { close } = $api.methods;
            const { setAccountTarget } = $api.setters;
            close.confirmDeleteModal();
            setAccountTarget.toDelete(null);
        };

        /**
         * Submit the action.
         * @param {Omit<IAccountTarget, 'id'>} account
         */
        const onSubmitAddAccount = async (account) => {
            const { close, refreshAccounts } = $api.methods;
            const { getSanitizedCreateRequest } = $api.getters;
            const { setLoading, setAccountTarget } = $api.setters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.addAccountModal();

            // Clear alerts, if present.
            clearAlert('add-success');
            clearAlert('add-errors');
            clearAlert('add-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[account::add]`);
                setLoading('loading');

                /** @type {import('@/api/accounts').ICreateAccountRequest} */
                const body = {
                    name: account.name,
                    reminder: account.reminder,
                    timezone: account.timezone,
                    temp_scale: account.tempScale,
                    account_tree_level: [...account.accountTreeLevel],
                };

                /** @type {ICreateAccountRequest} */
                const request = getSanitizedCreateRequest(body);

                /** @type {import('@/api').IResponseResult} */
                const result = await createAccount(request);

                // If successful, refresh accounts.
                if (result.status === 200) {
                    // Refresh the accounts index, if successful. (Otherwise, nothing to reload).
                    await refreshAccounts(true);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while adding an account`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `add-error`,
                        type: 'error',
                        title: `An unknown error occurred while adding an account`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[account::add]`);
                // Clear the target.
                setAccountTarget.toAdd(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
            }
        };

        /**
         * Submit the action.
         * @param {Pick<AccountResource, 'id' | 'name' | 'reminder' | 'timezone' | 'tempScale' | 'accountTreeLevel'>} account
         */
        const onSubmitEditAccount = async (account) => {
            const { close, refreshAccounts, refreshCurrentAccount } =
                $api.methods;
            const { getSanitizedEditRequest } = $api.getters;
            const { setLoading, setAccountTarget } = $api.setters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.editAccountModal();

            // Clear alerts, if present.
            clearAlert('edit-success');
            clearAlert('edit-errors');
            clearAlert('edit-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[account::edit]`);
                setLoading('loading');

                /** @type {import('@/api/accounts').IEditAccountTarget} */
                const target = pick(state.accountToEdit.value, 'id', 'name');

                /** @type {import('@/api/accounts').IEditAccountRequest} */
                const body = {
                    name: account.name,
                    reminder: account.reminder,
                    timezone: account.timezone,
                    temp_scale: account.tempScale,
                    account_tree_level: [...account.accountTreeLevel],
                };

                /** @type {IEditAccountRequest} */
                const request = getSanitizedEditRequest(body);

                /** @type {import('@/api').IResponseResult} */
                const result = await updateAccountById(target, request);

                // If successful, refresh accounts.
                if (result.status === 200) {
                    // Refresh the accounts index, if successful. (Otherwise, nothing to reload).
                    await refreshAccounts(true);
                    await refreshCurrentAccount(target);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while updating the account`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `edit-error`,
                        type: 'error',
                        title: `An unknown error occurred while editing an account`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[account::edit]`);
                // Clear the target.
                setAccountTarget.toEdit(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
            }
        };

        /**
         * Submit the action.
         */
        const onSubmitDeleteAccount = async () => {
            const { close, refreshAccounts } = $api.methods;
            const { setLoading, setAccountTarget } = $api.setters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.confirmDeleteModal();

            // Clear alerts, if present.
            clearAlert('delete-success');
            clearAlert('delete-errors');
            clearAlert('delete-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[account::delete]`);
                setLoading('loading');

                /** @type {import('@/api/accounts').IDeleteAccountTarget} */
                const target = {
                    id: state.accountToDelete.value.id,
                    name: state.accountToDelete.value.name,
                };

                /** @type {import('@/api').IResponseResult} */
                const result = await deleteAccountById(target);

                // If successful...
                if (result.status === 200) {
                    // Refresh the accounts index, if successful. (Otherwise, nothing to reload).
                    await refreshAccounts(true);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while deleting the account`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `delete-error`,
                        type: 'error',
                        title: `An unknown error occurred while deleting the account`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[account::delete]`);
                // Clear the target.
                setAccountTarget.toDelete(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
            }
        };

        /**
         * After initialization, run this event.
         */
        const onInit = async () => {
            // Initialize the column definitions.
            const { getColumnDefs } = $api.getters;
            state.colDefs.value = [...getColumnDefs()];
        };

        /** Event handlers and callbacks. */
        this.events = {
            onInit,
            onUpdateAccounts,
            get onClick() {
                return {
                    addAccount: onClickAddAccount,
                    editAccount: onClickEditAccount,
                    deleteAccount: onClickDeleteAccount,
                };
            },
            get onCancel() {
                return {
                    addAccount: onCancelAddAccount,
                    editAccount: onCancelEditAccount,
                    deleteAccount: onCancelDeleteAccount,
                };
            },
            get onSubmit() {
                return {
                    addAccount: onSubmitAddAccount,
                    editAccount: onSubmitEditAccount,
                    deleteAccount: onSubmitDeleteAccount,
                };
            },
        };
    }

    initMethods() {
        const $api = this;
        const { state, accounts } = $api.context;

        /**
         * Format the ISO string into just its date component.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const useDateComponentFormat = (params) => {
            const value = params.value;
            if (!isNil(value) && value !== '') {
                const date = DateTimeISO.parse(value);
                const formatted = formatISO(date, {
                    format: 'extended',
                    representation: 'date',
                });
                return formatted;
            }
            return 'No Date Provided';
        };

        /**
         * Update the row data after requesting accounts from the cached index.
         * @param {Boolean} [forceReload]
         */
        const refreshAccounts = async (forceReload = false) => {
            const { setLoading } = $api.setters;
            try {
                console.time(`[accounts::index] - Refreshing Account index:`);
                setLoading('loading');
                // ==== REFRESH ====
                const accountList = await accounts.refreshAccountsIndex(
                    forceReload
                );
                $api.events.onUpdateAccounts(accountList ?? []);
                // ==== END ====
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                throw error;
            } finally {
                console.timeEnd(
                    `[accounts::index] - Refreshing Account index:`
                );
            }
        };

        /**
         * Update the Vuex store if target matches current account.
         * @param {import('@/api/accounts').IEditAccountTarget} [target]
         */
        const refreshCurrentAccount = async (target) => {
            const { store } = $api.context;
            const { getSelectedAccount } = $api.getters;

            // GET the current account.
            const current = getSelectedAccount();

            // UPDATE current account, if the edit target matches.
            if (
                is.integer(current?.id) &&
                is.integer(target?.id) &&
                current.id === target.id
            ) {
                // FETCH latest account details.
                const updated = await fetchAccountById(target);

                // UPDATE account.
                store.commit('setCurrentAccount', updated);
            }
        };

        /** Open modal. */
        const openAddAccountModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('addAccount');
        };

        /** Open modal. */
        const openEditAccountModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('editAccount');
        };

        /** Open modal. */
        const openConfirmDeleteModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('confirmDelete');
        };

        /**
         * Close modal if it matches.
         * @param {'addAccount' | 'editAccount' | 'confirmDelete'} id
         */
        const closeModalIfOpen = (id) => {
            if (state.openModal.value === id) {
                // Close modal if it matches.
                $api.setters.setOpenModal(null);
            }
        };

        /**
         * Format the date.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const formatDate = (params) => {
            /** datetime value in the format of 'yyyy-MM-DDThh:mm:ss.sssZ' */
            const datetime = /** @type {String} */ (params.value);
            if (!isNil(datetime) && datetime !== '') {
                const [date, time] = datetime.split('T');
                return date;
            }
            return 'No date provided.';
        };

        /** Event triggers and methods. */
        this.methods = {
            useDateComponentFormat,
            refreshAccounts,
            refreshCurrentAccount,
            formatDate,
            get open() {
                return {
                    addAccountModal: openAddAccountModal,
                    editAccountModal: openEditAccountModal,
                    confirmDeleteModal: openConfirmDeleteModal,
                };
            },
            get close() {
                return {
                    addAccountModal: () => closeModalIfOpen('addAccount'),
                    editAccountModal: () => closeModalIfOpen('editAccount'),
                    confirmDeleteModal: () => closeModalIfOpen('confirmDelete'),
                };
            },
        };
    }
}

/**
 * Composable function that returns the initialized context object.
 */
export const useAccountManager = () => {
    const context = new AccountManager();
    return context.initialize();
};

// <!-- DEFAULT -->
export default useAccountManager;
