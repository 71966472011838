<template>
    <Panel>
        <template #header>
            <div class="flex flex-row justify-between">
                <div class="text-2xl text-black font-bold">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #default>
            <transition
                :appear="true"
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0"
                enter-to-class="transform opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="transform opacity-100"
                leave-to-class="transform opacity-0"
            >
                <AccountFields
                    :sourceAccount="sourceAccount"
                    :isEditing="isEditing"
                    :isLoading="isLoading"
                    :onCancel="onCancel"
                    :onSave="onSave"
                    :debug="debug"
                />
            </transition>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import AccountFields from '~AccountManager/components/AccountFields.vue';

    // <!-- TYPES -->
    /** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
    /** @typedef {import('@/features/account-manager/hooks/useAccountManager').IAccountTarget} IAccountTarget */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'EditAccountForm',
        components: {
            Panel,
            AccountFields,
        },
        props: {
            sourceAccount: {
                /** @type {V.PropType<IAccountTarget>} */
                type: Object,
            },
            isEditing: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            onCancel: {
                /** @type {V.PropType<() => void>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            onSave: {
                /** @type {V.PropType<(account: Omit<IAccountTarget, 'id'> & Partial<Pick<IAccountTarget, 'id'>>) => Promise<void>>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            debug: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            /** @type {V.ComputedRef<String>} Form header. */
            const title = computed(() => {
                const editing = props.isEditing;
                const action = editing ? 'Edit' : 'View';
                return `${action} Account`;
            });
            return {
                title,
            };
        },
    });
</script>
