<template>
    <div
        v-if="!!sourceUser"
        :class="[isLoading ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="dirtyUser"
            #default="context"
            :actions="false"
            :config="{ validationVisibility: 'blur' }"
        >
            <FormKit
                type="text"
                name="username"
                label="* Username"
                validation="required"
                :disabled="!isEditing"
            />
            <FormKit
                type="text"
                name="email"
                label="* Email"
                validation="email"
                :disabled="!isEditing"
            />
            <FormKit
                type="radio"
                name="role"
                label="* Access Type"
                validation="required"
                :disabled="!isEditing"
                :options="{
                    admin: 'Admin',
                    ['data-manager']: 'Data Manager',
                    ['data-analyst']: 'Data Analyst',
                }"
                :config="{
                    // config override applies to all nested FormKit components
                    classes: {
                        outer: 'mb-5 mt-3 user-access-radio-list',
                        wrapper: 'flex mt-2',
                        label: 'mx-3',
                    },
                }"
                @input="$emit('update:dirtyUser', { dirtyUser })"
            />
            <LoadingWrapper :isLoading="isAccountsLoading">
                <FormSection
                    class="pt-4"
                    title="Select Accounts"
                    v-show="showSelectAccounts"
                    :grid="[
                        'grid',
                        'grid-cols-1',
                        'mt-6',
                        'mb-6',
                        'gap-y-4',
                        'lg:grid-cols-2',
                        'max-w-3xl',
                    ]"
                >
                    <div>
                        <AgGridVue
                            id="selectUserAccountsGrid"
                            class="ag-theme-alpine"
                            style="width: 300px; height: 400px"
                            :columnDefs="userAccountsLeftColDefs"
                            :defaultColDef="defaultColDef"
                            :rowData="leftUserAccountsRowData"
                            :rowDragManaged="true"
                            :suppressMoveWhenRowDragging="true"
                            :animateRows="true"
                            :getRowNodeId="rowNodeIdGetter"
                            @grid-ready="onGridReady($event, 'Left')"
                        ></AgGridVue>
                    </div>
                    <div>
                        <AgGridVue
                            class="ag-theme-alpine"
                            style="width: 300px; height: 400px"
                            :columnDefs="userAccountsRightColDefs"
                            :defaultColDef="defaultColDef"
                            :rowData="rightUserAccountsRowData"
                            :rowDragManaged="true"
                            :getRowNodeId="rowNodeIdGetter"
                            :suppressMoveWhenRowDragging="true"
                            :animateRows="true"
                            @grid-ready="onGridReady($event, 'Right')"
                        ></AgGridVue>
                    </div>
                </FormSection>
            </LoadingWrapper>
            <p class="text-gray-400 text-sm">* indicates a required field</p>
            <FormSubmitCancel
                :allowSave="context.state.valid && !isLoading"
                :onSave="onClickSave"
                :onCancel="onClickCancel"
            />
        </FormKit>
        <div
            v-if="!!debug"
            class="mt-6"
        >
            <DebugFrame
                id="generic"
                :startHidden="frame.startHidden"
                :debug="frame.isEnabled"
                :data="frame.data"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, ref, onMounted } from 'vue';
    import { computedEager } from '@vueuse/core';

    // <!-- UTILITIES -->
    import clone from 'just-clone';
    import pick from 'just-pick';

    // <!-- COMPONENTS -->
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import { AgGridVue } from 'ag-grid-vue3';
    import DebugFrame from '@/components/debug/DebugFrame.vue';

    // <!-- TYPES -->

    /** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
    /** @typedef {import('@/models/users/User').UserResource} UserResource */

    /** @typedef {import('@formkit/core').FormKitNode} FormKitNode */
    /** @typedef {AgGrid.GetRowNodeIdFunc} GetRowNodeIdFunc */
    /** @typedef {AgGrid.Events.GridReadyEvent} GridReadyEvent */

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useUserManager } from '~UserManager/hooks/useUserManager';
    import { syncRefs } from '@vueuse/shared';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UserFields',
        components: {
            FormSubmitCancel,
            FormSection,
            AgGridVue,
            LoadingWrapper,
            DebugFrame,
        },
        props: {
            sourceUser: {
                /** @type {V.PropType<Pick<UserResource, 'username' | 'email' | 'role'> & Partial<Pick<UserResource, 'id' | 'accounts'>>>} */
                type: Object,
            },
            isEditing: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            onCancel: {
                /** @type {V.PropType<() => void>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            onSave: {
                /** @type {V.PropType<(user: Pick<UserResource, 'username' | 'email' | 'role'>, selectedAccounts: Pick<AccountResource, 'name' | 'id'>[]) => Promise<void>>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            debug: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        setup(props, context) {
            const manager = useUserManager();
            const { grid, data, actions } = manager;

            // ==== STATE ====
            const { defaultColDef } = grid;
            const {
                userAccountsLeftColDefs,
                userAccountsRightColDefs,
                leftUserAccountsRowData,
                rightUserAccountsRowData,
                isAccountsLoading,
            } = data;

            // ==== STATE ====
            /** @type {V.Ref<Pick<UserResource, 'username' | 'email' | 'role'> & Partial<Pick<UserResource, 'id'>>>} */
            const dirtyUser = ref(null);

            /** @type {V.Ref<Pick<AccountResource, 'name' | 'id'>[]>} */
            const selectedAccounts = ref(rightUserAccountsRowData);

            /** @type {V.ComputedRef<Boolean>} Are the select account inputs visible? */
            const showSelectAccounts = computed(() => {
                return dirtyUser.value.role !== 'admin';
            });

            // ==== WATCHERS ====

            // Select accounts should be kept 1:1 with the right data box.
            syncRefs(rightUserAccountsRowData, selectedAccounts);

            // ==== EVENTS ====

            /** Clone the clean user. */
            const resetDirtyUser = () => {
                const instance = clone(
                    pick(
                        props.sourceUser,
                        'id',
                        'username',
                        'email',
                        'role',
                        'accounts'
                    )
                );
                dirtyUser.value = instance;
                if (!!instance.accounts && instance.accounts?.length > 0) {
                    rightUserAccountsRowData.value = instance.accounts.map(
                        (a) => pick(a, 'id', 'name')
                    );
                }
            };

            /** Save the dirty user. */
            const onClickSave = async () => {
                await props.onSave(dirtyUser.value, selectedAccounts.value);
            };

            /** Clear the dirty user. */
            const onClickCancel = () => {
                resetDirtyUser();
                props.onCancel();
            };

            /**
             * Store the grid API for the appropriate side.
             * @param {GridReadyEvent} params
             * @param {'Left' | 'Right'} source
             */
            const onGridReady = (params, source) => {
                const api = params.api;
                if (source === 'Left') {
                    data.leftUserAccountsApi.value = api;
                }

                if (source === 'Right') {
                    data.rightUserAccountsApi.value = api;
                }

                if (
                    data.rightUserAccountsApi.value &&
                    data.leftUserAccountsApi.value
                ) {
                    addGridDropZone('Left', data.leftUserAccountsApi.value);
                    addGridDropZone('Right', data.rightUserAccountsApi.value);
                }

                api.sizeColumnsToFit();
            };

            const onLeftGridApiUpdate = () => {
                // data.leftUserAccountsRowData.value =
                //     data.leftUserAccountsApi.value
                //         .getRenderedNodes()
                //         .map((n) => n.data);
                /** @type {AgGrid.RowNode<Pick<AccountResource, 'name' | 'id'>>[]} */
                const nodes = [];
                data.leftUserAccountsApi.value.forEachNode((node) => {
                    // console.log('[left]', node.data);
                    nodes.push(node);
                });
                data.leftUserAccountsRowData.value = nodes.map((n) => n.data);
            };

            const onRightGridApiUpdate = () => {
                // data.rightUserAccountsRowData.value =
                //     data.rightUserAccountsApi.value
                //         .getRenderedNodes()
                //         .map((n) => n.data);
                /** @type {AgGrid.RowNode<Pick<AccountResource, 'name' | 'id'>>[]} */
                const nodes = [];
                data.rightUserAccountsApi.value.forEachNode((node) => {
                    // console.log('[right]', node.data);
                    nodes.push(node);
                });
                data.rightUserAccountsRowData.value = nodes.map((n) => n.data);
            };

            /**
             * Update the row data once the grid api has been updated.
             */
            const onGridApiUpdate = () => {
                if (
                    data.leftUserAccountsApi.value != null &&
                    data.rightUserAccountsApi.value != null
                ) {
                    onLeftGridApiUpdate();
                    onRightGridApiUpdate();
                }
                console.log(
                    `[update::left]`,
                    data.leftUserAccountsRowData.value
                );
                console.log(
                    `[update::right]`,
                    data.rightUserAccountsRowData.value
                );
                console.log(`[update::selected]`, selectedAccounts.value);
            };

            /**
             * Register target dropzone on the provided source API.
             * @param {'Left' | 'Right'} source
             * @param {AgGrid.GridApi} sourceApi
             */
            const addGridDropZone = (source, sourceApi) => {
                const targetApi =
                    source === 'Left'
                        ? data.rightUserAccountsApi
                        : data.leftUserAccountsApi;
                const targetDropZone = targetApi.value.getRowDropZoneParams({
                    onDragStop: (params) => {
                        /** @type {Pick<AccountResource, 'name' | 'id'>} */
                        const droppedData = params.nodes[0].data;
                        switch (source) {
                            // When dragging from the left side, dropping on the right.
                            case 'Left':
                                data.leftUserAccountsApi.value.forEachNode(
                                    (node) => {
                                        if (node.data.id === droppedData.id) {
                                            const row = node.data;
                                            data.leftUserAccountsApi.value.applyTransaction(
                                                {
                                                    remove: [row],
                                                }
                                            );
                                        }
                                    }
                                );
                                onGridApiUpdate();
                                return;
                            // When dragging from the right side, dropping on the left.
                            case 'Right':
                                data.rightUserAccountsApi.value.forEachNode(
                                    (node) => {
                                        if (node.data.id === droppedData.id) {
                                            const row = node.data;
                                            data.rightUserAccountsApi.value.applyTransaction(
                                                {
                                                    remove: [row],
                                                }
                                            );
                                        }
                                    }
                                );
                                onGridApiUpdate();
                                return;
                            default:
                                console.error(
                                    `Unknown side ${source}. No operation performed.`
                                );
                                return;
                        }
                    },
                });
                sourceApi.addRowDropZone(targetDropZone);
            };

            /** @type {GetRowNodeIdFunc} */
            const rowNodeIdGetter = (data) => {
                return data?.id;
            };

            const refreshAccountsGrid = async () => {
                await actions.refreshAccounts(false, props.sourceUser);
            };

            // ==== DEBUG ====
            /**
             * Computed debug frame.
             */
            const frame = computed(() => {
                // Prepare data.
                const data = [
                    DebugObject.create(`Is Editing?`, {
                        isEditing: props.isEditing,
                    }),
                    DebugObject.create(`Is Loading?`, {
                        isLoading: props.isLoading,
                    }),
                    DebugObject.create(`Source User Details`, {
                        sourceUser: props.sourceUser,
                    }),
                    DebugObject.create(`Dirty User`, {
                        dirtyUser: {
                            ...dirtyUser.value,
                            accounts: [...selectedAccounts.value],
                        },
                    }),
                ];
                // Return new frame instance.
                return useDebugFrame({
                    isEnabled: true,
                    startHidden: true,
                    data,
                });
            });

            // ==== LIFECYCLE ====
            onMounted(() => {
                // Reset the dirty user.
                resetDirtyUser();
                // Refresh the users on initial page load.
                refreshAccountsGrid();
            });

            // ==== EXPOSE ====
            return {
                frame,
                dirtyUser,
                defaultColDef,
                isAccountsLoading,
                userAccountsLeftColDefs,
                userAccountsRightColDefs,
                leftUserAccountsRowData,
                rightUserAccountsRowData,
                showSelectAccounts,
                rowNodeIdGetter,
                refreshAccountsGrid,
                onClickSave,
                onClickCancel,
                onGridReady,
            };
        },
    });
</script>

<style lang="scss">
    .user-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
