<template>
    <router-view v-slot="{ Component }">
        <!-- <keep-alive> -->
        <component
            v-if="isAdministrator"
            :is="Component"
        />
        <!-- </keep-alive> -->
    </router-view>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';
    import { useStore } from 'vuex';

    // <!-- TYPES -->
    import { ECNBState } from '@/store/types/ECNBStore';
    /** @template T  */ /** Ref} Ref<T> */
    /** @template T @typedef {import('vuex').Store} Store<T> */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Admin',
        setup(props, context) {
            /** @param {Store<ECNBState>} store */
            const useUser = (store) => {
                const user = computed(() => store.state.users.me);
                return user;
            };

            /** @param {V.Ref<Store<ECNBState>['state']['users']['me']>} user */
            const useAdministrator = (user) => {
                const isAdministrator = computed(
                    () => user.value.role === 'admin'
                );
                return isAdministrator;
            };

            // ==== COMPOSABLES ====
            const store = useStore();
            const user = useUser(store);
            const isAdministrator = useAdministrator(user);

            return {
                isAdministrator,
            };
        },
    });
</script>
