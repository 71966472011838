<template>
    <Panel>
        <template #header>
            <div class="flex flex-row justify-between">
                <div class="text-2xl text-black font-bold">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #default>
            <transition
                :appear="true"
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0"
                enter-to-class="transform opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="transform opacity-100"
                leave-to-class="transform opacity-0"
            >
                <UserFields
                    :sourceUser="sourceUser"
                    :isEditing="isEditing"
                    :isLoading="isLoading"
                    :onCancel="onCancel"
                    :onSave="onSave"
                    :debug="debug"
                />
            </transition>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import UserFields from '~UserManager/components/UserFields.vue';

    // <!-- TYPES -->

    /** @typedef {import('@/models/users/User').UserResource} UserResource */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AddUserForm',
        components: {
            Panel,
            UserFields,
        },
        props: {
            sourceUser: {
                /** @type {V.PropType<Pick<UserResource, 'username' | 'email' | 'role'>>} */
                type: Object,
            },
            isEditing: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            onCancel: {
                /** @type {V.PropType<() => void>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            onSave: {
                /** @type {V.PropType<(user: Pick<UserResource, 'username' | 'email' | 'role'>) => Promise<void>>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            debug: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            /** @type {String} Form header. */
            const title = `Add User`;
            return {
                title,
            };
        },
    });
</script>
