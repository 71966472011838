<template>
    <BasicModal
        :show="isAddAccountModalOpen"
        @close="onCancel.addAccount"
        hideCloseButton
    >
        <AddAccountForm
            :sourceAccount="accountToAdd"
            :isEditing="true"
            :isLoading="isLoading"
            :onCancel="onCancel.addAccount"
            :onSave="onSubmit.addAccount"
            :debug="isDebug"
        />
    </BasicModal>
    <BasicModal
        :show="isEditAccountModalOpen"
        @close="onCancel.editAccount"
        hideCloseButton
    >
        <EditAccountForm
            :sourceAccount="accountToEdit"
            :isEditing="true"
            :isLoading="isLoading"
            :onCancel="onCancel.editAccount"
            :onSave="onSubmit.editAccount"
            :debug="isDebug"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Account"
        confirmationText="Are you sure you want to delete this account? This action cannot be undone."
        :open="isConfirmDeleteModalOpen"
        @delete="onSubmit.deleteAccount"
        @cancel="onCancel.deleteAccount"
        @close="onCancel.deleteAccount"
    />

    <AdminContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        View, add, edit, and remove accounts.
                    </PageDescription>
                    <ModalButton
                        theme="primary"
                        label="Add Account"
                        :busy="isLoading"
                        @click.prevent="onClick.addAccount"
                    />
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="true"
                        :paginationPageSize="25"
                        :columnDefs="colDefs"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                        overlayNoRowsTemplate="No accounts to display."
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </AdminContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="alerts.state.alerts.value"
            @dismiss:alert="alerts.methods.dismissAlert($event)"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';
    import LoadingWrapper from '@components/LoadingWrapper.vue';
    import PageWrapper from '@components/PageWrapper.vue';
    import PageIntro from '@components/PageIntro.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import PageDescription from '@components/PageDescription.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import AdminContainer from '~Admin/components/wrappers/AdminContainer.vue';
    import AddAccountForm from '~AccountManager/components/AddAccountForm.vue';
    import EditAccountForm from '~AccountManager/components/EditAccountForm.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- COMPOSABLES -->
    import { useAccountManager } from '~AccountManager/hooks/useAccountManager';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Accounts',
        components: {
            AgGridVue,
            LoadingWrapper,
            PageWrapper,
            PageIntro,
            PageDescription,
            ModalButton,
            AdminContainer,
            BasicModal,
            BaseAlert,
            AddAccountForm,
            EditAccountForm,
            ConfirmDeleteModal,
        },
        setup(props, context) {
            // ==== COMPOSABLES ====
            const manager = useAccountManager();
            const { alerts, grid, data, actions } = manager;
            const { defaultColDef } = grid;
            const {
                accountIndex,
                accountToAdd,
                accountToEdit,
                rowData,
                colDefs,
            } = data;

            // ==== STATE ====
            /** Debug environment flag. */
            const isDebug = process.env.NODE_ENV !== 'production';
            /** @type {{ enabled: Boolean, size: Number }} */
            const pagination = { enabled: true, size: 25 };

            // ==== COMPUTED PROPERTIES ====
            const {
                isLoading,
                isAddAccountModalOpen,
                isEditAccountModalOpen,
                isConfirmDeleteModalOpen,
            } = data;

            // ==== EVENTS ====
            const { onGridReady, onColumnResized } = grid;
            const { onClick, onCancel, onSubmit } = actions;
            const { refreshAccounts } = actions;

            // ==== LIFECYCLE ====
            onMounted(async () => {
                // Refresh the accounts on initial page load.
                await refreshAccounts(false);
            });

            // ==== EXPOSE ====
            return {
                manager,
                alerts,
                // STATE
                accountToAdd,
                accountToEdit,
                accountIndex,
                defaultColDef,
                rowData,
                colDefs,
                // CONDITIONALS,
                isDebug,
                isLoading,
                isAddAccountModalOpen,
                isEditAccountModalOpen,
                isConfirmDeleteModalOpen,
                // EVENTS
                onClick,
                onCancel,
                onSubmit,
                onGridReady,
                onColumnResized,
            };
        },
    });
</script>
