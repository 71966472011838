<template>
    <Panel>
        <template #header>
            <div class="flex flex-row justify-between">
                <div class="text-2xl text-black font-bold">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #default>
            <transition
                :appear="true"
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0"
                enter-to-class="transform opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="transform opacity-100"
                leave-to-class="transform opacity-0"
            >
                <NARAStandardFields
                    v-if="!!sourceStandard"
                    :sourceStandard="sourceStandard"
                    :isEditing="isEditing"
                    :isLoading="isLoading"
                    :onCancel="onCancel"
                    :onSave="onSave"
                />
            </transition>
        </template>
        <template
            v-if="frame.isEnabled"
            #footer
        >
            <DebugFrame
                id="generic"
                :startHidden="frame.startHidden"
                :debug="frame.isEnabled"
                :data="frame.data"
            />
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import NARAStandardFields from '~NARAStandards/components/NARAStandardFields.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';

    // <!-- TYPES -->

    /** @typedef {import('@/models/standards/NARAStandard').NARAStandardResource} NARAStandardResource */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'EditNARAStandardForm',
        components: {
            Panel,
            NARAStandardFields,
            DebugFrame,
        },
        props: {
            sourceStandard: {
                /** @type {V.PropType<Pick<NARAStandardResource, 'name'>>} */
                type: Object,
            },
            isEditing: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            onCancel: {
                /** @type {V.PropType<() => void>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            onSave: {
                /** @type {V.PropType<(account: Pick<NARAStandardResource, 'name'>) => Promise<void>>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
        },
        setup(props) {
            /** @type {V.ComputedRef<String>} Form header. */
            const title = computed(() => {
                const editing = props.isEditing;
                const action = editing ? 'Edit' : 'View';
                return `${action} Standard`;
            });

            // DEBUG
            /**
             * Computed debug frame.
             */
            const frame = computed(() => {
                // Prepare data.
                const data = [
                    DebugObject.create(`Is Editing?`, {
                        isEditing: props.isEditing,
                    }),
                    DebugObject.create(`Is Loading?`, {
                        isLoading: props.isLoading,
                    }),
                    DebugObject.create(`Source Standard Details`, {
                        sourceStandard: props.sourceStandard,
                    }),
                ];
                // Return new frame instance.
                return useDebugFrame({
                    isEnabled: true,
                    startHidden: true,
                    data,
                });
            });
            return {
                title,
                frame,
            };
        },
    });
</script>
