// <!-- API -->
import {
    createUser,
    updateUserById,
    resetUserPassword,
    deleteUserById,
} from '@/api/users';
import { ref, computed } from 'vue';

// <!-- COMPONENTS -->
import UserManagerTableIcons from '~UserManager/components/UserManagerTableIcons.vue';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import pick from 'just-pick';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import { useUserIndex } from '@/hooks/cache/useUserIndex';
import { useAccountsIndex } from '@/hooks/cache/useAccountsIndex';
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';

/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {import('@/models/users/User').UserResource} UserResource */
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */

/** @typedef {AgGrid.GridApi} GridApi */

// <!-- COMPOSABLE -->
/**
 * Provides access to all composable submodules.
 */
class UserManager {
    /**
     * Instantiate a new UserManager composable.
     * @param {Object} [props] Props to pass to the UserManager.
     * @param {Store<ECNBState>} [props.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [props.alerts] Alerts composable.
     * @param {ReturnType<useAgGridVue>} [props.grid] AgGrid composable.
     * @param {ReturnType<useUserIndex>} [props.users] User index composable.
     * @param {ReturnType<useAccountsIndex>} [props.accounts] Accounts index composable.
     */
    constructor(props = {}) {
        // Deconstruct parameters.
        const { store, alerts, grid, users, accounts } = props ?? {};

        /** @type {Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAgGridVue>} */
        this.grid = grid ?? useAgGridVue();

        /** @type {ReturnType<useUserIndex>} */
        this.users = users ?? useUserIndex();

        /** @type {ReturnType<useAccountsIndex>} */
        this.accounts = accounts ?? useAccountsIndex();

        /** @type {UserManagerConstants} */
        this.constants = new UserManagerConstants(this);

        /** @type {UserManagerState} */
        this.state = new UserManagerState(this);

        /** @type {UserManagerCache} */
        this.cached = new UserManagerCache(this);

        /** @type {UserManagerAPI} */
        this.api = new UserManagerAPI(this);

        /** @type {Boolean} */
        this.initialized = false;
    }

    /**
     * Initialize respective submodule
     */
    initialize() {
        const $context = this;
        if (!$context.initialized) {
            // Initialize sequentially. These must be synchronous.
            $context.constants.initialize();
            $context.state.initialize();
            $context.cached.initialize();
            $context.api.initialize();
            $context.initialized = true;
            // If an onInit event exists, invoke it now.
            $context.api.events?.onInit?.();
            // Return the context.
            return $context;
        }
    }

    /**
     * Get access to the module setters.
     */
    get register() {
        const $context = this;
        return {
            /** @param {UserManager['constants']} instance */
            constants: (instance) => {
                $context.constants = instance;
                return $context;
            },
            /** @param {UserManager['state']} instance */
            state: (instance) => {
                $context.state = instance;
                return $context;
            },
            /** @param {UserManager['cached']} instance */
            cached: (instance) => {
                $context.cached = instance;
                return $context;
            },
            /** @param {UserManager['api']} instance */
            api: (instance) => {
                $context.api = instance;
                return $context;
            },
        };
    }

    /**
     * Get reactive data and computed properties.
     * @returns {Omit<UserManagerConstants, 'initialize'> & Omit<UserManagerState, 'initialize'> & Omit<UserManagerCache, 'initialize' | 'initStatusConditionals' | 'initOpenModalConditionals' | 'initUserTargets'>}
     */
    get data() {
        const $context = this;
        return {
            ...$context.constants,
            ...$context.state,
            ...$context.cached,
        };
    }

    /**
     * Get the actions.
     * @returns {UserManagerAPI['events'] & UserManagerAPI['methods']}
     */
    get actions() {
        const $context = this;
        return {
            ...$context.api.events,
            ...$context.api.methods,
        };
    }
}

// ==== CONSTANTS ====
/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class UserManagerConstants {
    /**
     * Instantiate submodule.
     * @param {UserManager} context
     */
    constructor(context) {
        /** @type {UserManager} */
        this.context = context;
        this.context.register.constants(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        /** Loading status IDs. */
        this.LoadingIDs = /** @type {const} */ ([
            'idle',
            'loading',
            'success',
            'failure',
        ]);
        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ([
            'addUser',
            'editUser',
            'viewUser',
            'confirmDelete',
            'confirmResetPassword',
        ]);
    }
}

// ==== STATE ====
/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class UserManagerState {
    /**
     * Instantiate submodule.
     * @param {UserManager} context
     */
    constructor(context) {
        /** @type {UserManager} */
        this.context = context;
        this.context.register.state(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== STATUS ====
        /** @type {V.Ref<'idle' | 'loading' | 'success' | 'failure'>} */
        this.status = ref('idle');

        // ==== OPEN MODALS ====
        /** @type {V.Ref<'addUser' | 'editUser' | 'confirmDelete' | 'confirmResetPassword'>} Only one modal can be open at a time. */
        this.openModal = ref(null);

        // ==== USER INDEX ====
        /** @type {V.Ref<Map<Number, UserResource>>} */
        this.userIndex = ref(new Map());

        // ==== USER TARGETS ====
        /** @type {V.Ref<Pick<UserResource, 'username' | 'email' | 'role' | 'accounts'>>} User target. */
        this.userToAdd = ref(null);

        /** @type {V.Ref<Pick<UserResource, 'id' | 'username' | 'email' | 'role' | 'accounts'>>} User target. */
        this.userToEdit = ref(null);

        /** @type {V.Ref<Pick<UserResource, 'id' | 'username' | 'email' | 'role'>>} User target. */
        this.userToDelete = ref(null);

        /** @type {V.Ref<Pick<UserResource, 'id' | 'username' | 'email' | 'role'>>} User target. */
        this.userToResetPassword = ref(null);

        // ==== AG GRID ====
        /** @type {V.Ref<Array<UserResource>>} */
        this.rowData = ref([]);

        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.colDefs = ref([]);

        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.userAccountsLeftColDefs = ref([]);

        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.userAccountsRightColDefs = ref([]);

        /** @type {V.Ref<Array<Pick<AccountResource, 'id' | 'name'>>>} */
        this.leftUserAccountsRowData = ref([]);

        /** @type {V.Ref<Array<Pick<AccountResource, 'id' | 'name'>>>} */
        this.rightUserAccountsRowData = ref([]);

        /** @type {V.Ref<GridApi>} */
        this.leftUserAccountsApi = ref(null);

        /** @type {V.Ref<GridApi>} */
        this.rightUserAccountsApi = ref(null);
    }
}

// ==== COMPUTED PROPERTIES ====
/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class UserManagerCache {
    /**
     * Instantiate submodule containing computed properties.
     * @param {UserManager} context
     */
    constructor(context) {
        /** @type {UserManager} */
        this.context = context;
        this.context.register.cached(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== CONDITIONALS (STATUS) ====
        this.initStatusConditionals();
        // ==== CONDITIONALS (MODALS) ====
        this.initOpenModalConditionals();
    }

    /**
     * Initialize the status conditionals.
     */
    initStatusConditionals() {
        const { state } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isIdle = computed(() => {
            return state.status.value === 'idle';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoading = computed(() => {
            return (
                state.status.value === 'loading' ||
                this.context.users.isFetching.value === true
            );
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isAccountsLoading = computed(() => {
            return (
                state.status.value === 'loading' ||
                this.context.accounts.isFetching.value === true
            );
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithSuccess = computed(() => {
            return state.status.value === 'success';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithFailure = computed(() => {
            return state.status.value === 'failure';
        });
    }

    /**
     * Initialize the open modal conditionals.
     */
    initOpenModalConditionals() {
        const { state } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isAddUserModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'addUser';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isEditUserModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'editUser';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isConfirmDeleteModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'confirmDelete';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isConfirmResetPasswordModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'confirmResetPassword';
        });
    }
}

// ==== API ====
/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class UserManagerAPI {
    /**
     * Instantiate submodule containing computed properties.
     * @param {UserManager} context
     */
    constructor(context) {
        /** @type {UserManager} */
        this.context = context;
        this.context.register.api(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== GETTERS ====
        this.initGetters();
        // ==== SETTERS ====
        this.initSetters();
        // ==== METHODS ====
        this.initMethods();
        // ==== EVENTS ====
        this.initEventHandlers();
    }

    initGetters() {
        const $api = this;
        const { state, store } = $api.context;

        /**
         * Get keyed column definitions.
         */
        const getColumnSchema = () => {
            return {
                /** @type {AgGrid.ColumnDef} Table icons with button actions. */
                icons: {
                    headerName: '',
                    field: 'id',
                    cellRendererFramework: UserManagerTableIcons,
                    lockPosition: true,
                    filter: false,
                    maxWidth: 120,
                    cellRendererParams: {
                        /**
                         * Handle editing of a user.
                         * @param {Object} event
                         * @param {Number} index User index.
                         */
                        handleEdit: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const user = state.userIndex.value.get(id);
                            $api.events.onClick.editUser(user);
                        },
                        /**
                         * Handle deletion of a user.
                         * @param {Object} event
                         * @param {Number} index User index.
                         */
                        handleDelete: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const user = state.userIndex.value.get(id);
                            $api.events.onClick.deleteUser(user);
                        },
                        /**
                         * Handle reseting user password.
                         * @param {Object} event
                         * @param {Number} index User index.
                         */
                        handleResetPassword: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const user = state.userIndex.value.get(id);
                            $api.events.onClick.resetUserPassword(user);
                        },
                    },
                },
                username: {
                    headerName: 'Username',
                    field: 'username',
                    maxWidth: 130,
                    sort: 'asc',
                    comparator: (valueA, valueB) => {
                        const valueALower = valueA.toLowerCase().trim();
                        const valueBLower = valueB.toLowerCase().trim();
                        return valueALower.localeCompare(valueBLower, 'en');
                    },
                    autoHeight: true,
                },
                email: {
                    headerName: 'User Email',
                    field: 'email',
                    minWidth: 450,
                },
                dateCreated: {
                    headerName: 'Date Created',
                    valueFormatter: $api.methods.formatDate,
                    field: 'dateCreated',
                },
                role: {
                    headerName: 'Access Type',
                    field: 'role',
                },
            };
        };

        /**
         * Get column definitions in ordered array.
         * @returns {AgGrid.ColumnDef[]}
         */
        const getColumnDefs = () => {
            const schema = getColumnSchema();
            return [
                schema.icons,
                schema.username,
                schema.email,
                schema.dateCreated,
                schema.role,
            ];
        };

        const getUserAccountsLeftColumnDefs = () => {
            return [
                {
                    headerName: 'Accounts',
                    field: 'name',
                    rowDrag: true,
                    cellClass: 'flex items-center',
                },
            ];
        };

        const getUserAccountsRightColumnDefs = () => {
            return [
                {
                    headerName: 'Selected Accounts',
                    field: 'name',
                    rowDrag: true,
                    cellClass: 'flex items-center',
                },
            ];
        };

        /**
         * Map access level into its corresponding display text.
         * @param {String} value
         */
        const getAccessLevelDisplayText = (value) => {
            const id = value.toLowerCase();
            switch (id) {
                case 'data-manager':
                    return 'Data Manager';
                case 'data-analyst':
                    return 'Data Analyst';
                case 'admin':
                    return 'Admin';
                default:
                    return value;
            }
        };

        /**
         * Create user index from array,
         * @param {UserResource[]} users
         */
        const getUsersAsIndex = (users) => {
            /** @type {[ id: Number, user: UserResource ][]} */
            const entries = users.map((u) => {
                /** @type {[ id: Number, user: UserResource ]} */
                const entry = [u.id, { ...u }];
                return entry;
            });
            /** Get map. */
            return new Map(entries);
        };

        /**
         * Cast account resources into row data form.
         * @param {AccountResource[]} accounts
         * @returns {Pick<AccountResource, 'id' | 'name'>[]}
         */
        const getAccountsForListBox = (accounts) => {
            if (!!accounts && accounts?.length > 0) {
                return accounts.map((a) => {
                    const { id, name } = a;
                    return { id, name };
                });
            } else {
                return [];
            }
        };

        const getUserAccountsForPayload = (accounts) => {
            return accounts.map((acct) => {
                return acct.id.toString();
            });
        };

        /**
         * Map
         * @param {UserResource[]} users
         * @returns
         */
        const getUsersAsRowData = (users) => {
            return users.map((user) => getUserAsRecord(user));
        };

        /**
         * Copy user and modify it for the row data.
         * @param {UserResource} user
         */
        const getUserAsRecord = (user) => ({
            ...user,
            role: getAccessLevelDisplayText(user.role),
        });

        /**
         * Copy user from index as a selected user target.
         * @param {UserResource['id']} id
         */
        const getUserAsTarget = (id) => {
            const source = state.userIndex.value.get(id);
            return {
                ...pick(source, 'id', 'username', 'email', 'role', 'accounts'),
            };
        };

        /** Getter calls that provide live accessors. */
        this.getters = {
            getColumnSchema,
            getColumnDefs,
            getUserAccountsLeftColumnDefs,
            getUserAccountsRightColumnDefs,
            getAccessLevelDisplayText,
            getUsersAsIndex,
            getUsersAsRowData,
            getUserAsRecord,
            getUserAsTarget,
            getAccountsForListBox,
            getUserAccountsForPayload,
        };
    }

    initSetters() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Set the loading status.
         * @param {'idle' | 'loading' | 'success' | 'failure'} [id]
         */
        const setLoading = (id = 'idle') => {
            state.status.value = id ?? 'idle';
        };

        /**
         * Set the open modal.
         * @param {'addUser' | 'editUser' | 'confirmDelete' | 'confirmResetPassword'} id
         */
        const setOpenModal = (id = null) => {
            state.openModal.value = id ?? null;
        };

        /**
         * Set user index instance.
         * @param {Map<Number, UserResource>} index
         */
        const setUserIndex = (index) => {
            state.userIndex.value = new Map(index.entries());
        };

        /**
         * Set user target for new user.
         * @param {Pick<UserResource, 'username' | 'email' | 'role' | 'accounts'>} target
         */
        const setAddUserTarget = (target) => {
            state.userToAdd.value = target;
            if (target !== null) {
                const DEFAULT_ROLE = 'data-manager';
                state.userToAdd.value.role =
                    target?.role === ''
                        ? DEFAULT_ROLE
                        : target?.role ?? DEFAULT_ROLE;
            }
        };

        /**
         * Set user target for the corresponding id.
         * @param {UserResource['id']} id
         */
        const setEditUserTarget = (id) => {
            const { getUserAsTarget } = $api.getters;
            const target = id ? getUserAsTarget(id) : null;
            state.userToEdit.value = target;
        };

        /**
         * Set user target for the corresponding id.
         * @param {UserResource['id']} id
         */
        const setDeleteUserTarget = (id) => {
            const { getUserAsTarget } = $api.getters;
            const target = id ? getUserAsTarget(id) : null;
            state.userToDelete.value = target;
        };

        /**
         * Set user target for the corresponding id.
         * @param {UserResource['id']} id
         */
        const setResetPasswordUserTarget = (id) => {
            const { getUserAsTarget } = $api.getters;
            const target = id ? getUserAsTarget(id) : null;
            state.userToResetPassword.value = target;
        };

        /**
         * Set the row data.
         * @param {UserResource[]} data
         */
        const setRowData = (data) => {
            state.rowData.value = [...data];
        };

        /**
         * Set the row data.
         * @param {Pick<AccountResource, 'id' | 'name'>[]} data
         */
        const setLeftUserAccountsRowData = (data) => {
            state.leftUserAccountsRowData.value = [...data];
        };

        /**
         * Set the row data.
         * @param {Pick<AccountResource, 'id' | 'name'>[]} data
         */
        const setRightUserAccountsRowData = (data) => {
            state.rightUserAccountsRowData.value = [...data];
        };

        /** Setters for mutation of the state. */
        this.setters = {
            setLoading,
            setOpenModal,
            setUserIndex,
            setRowData,
            setLeftUserAccountsRowData,
            setRightUserAccountsRowData,
            get setUserTarget() {
                return {
                    toAdd: setAddUserTarget,
                    toEdit: setEditUserTarget,
                    toDelete: setDeleteUserTarget,
                    toResetPassword: setResetPasswordUserTarget,
                };
            },
        };
    }

    initEventHandlers() {
        const $api = this;
        const { state, alerts } = $api.context;
        /**
         * When users index is loaded/refreshed,
         * update the row data, with mapped account access levels.
         * @param {UserResource[]} accountUsers
         */
        const onUpdateUsers = (accountUsers) => {
            const { getUsersAsIndex, getUsersAsRowData } = $api.getters;
            const { setUserIndex, setRowData } = $api.setters;
            const accountUserIndex = getUsersAsIndex(accountUsers);
            const accountUserData = getUsersAsRowData(accountUsers);
            setUserIndex(accountUserIndex);
            setRowData(accountUserData);
        };

        const onUpdateAccounts = (_accounts, sourceUser) => {
            const { getAccountsForListBox } = $api.getters;
            const { setLeftUserAccountsRowData, setRightUserAccountsRowData } =
                $api.setters;

            // GET index and selected.
            const accountIndex = getAccountsForListBox(_accounts);
            const rightAccountList = getAccountsForListBox(
                sourceUser?.accounts
            );
            const leftAccountList = accountIndex.filter(
                (a) => !rightAccountList.some((account) => account.id === a.id)
            );

            // SET the left and right user account row data.
            setLeftUserAccountsRowData(leftAccountList);
            setRightUserAccountsRowData(rightAccountList || []);
        };

        /**
         * On click add user.
         */
        const onClickAddUser = () => {
            const { open } = $api.methods;
            const { setUserTarget } = $api.setters;
            setUserTarget.toAdd({
                username: '',
                email: '',
                role: '',
                // @ts-ignore
                accounts: [],
            });
            open.addUserModal();
        };

        /**
         * On click edit user.
         * @param {UserResource} target
         */
        const onClickEditUser = (target) => {
            const { open } = $api.methods;
            const { setUserTarget } = $api.setters;
            setUserTarget.toEdit(target.id);
            open.editUserModal();
        };

        /**
         * On click delete user.
         * @param {UserResource} target
         */
        const onClickDeleteUser = (target) => {
            const { open } = $api.methods;
            const { setUserTarget } = $api.setters;
            setUserTarget.toDelete(target.id);
            open.confirmDeleteModal();
        };

        /**
         * On click reset password for the target user.
         * @param {UserResource} target
         */
        const onClickResetUserPassword = (target) => {
            const { open } = $api.methods;
            const { setUserTarget } = $api.setters;
            setUserTarget.toResetPassword(target.id);
            open.confirmResetPasswordModal();
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelAddUser = () => {
            const { close } = $api.methods;
            const { setUserTarget } = $api.setters;
            close.addUserModal();
            setUserTarget.toAdd(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelEditUser = () => {
            const { close } = $api.methods;
            const { setUserTarget } = $api.setters;
            close.editUserModal();
            setUserTarget.toEdit(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelDeleteUser = () => {
            const { close } = $api.methods;
            const { setUserTarget } = $api.setters;
            close.confirmDeleteModal();
            setUserTarget.toDelete(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelResetUserPassword = () => {
            const { close } = $api.methods;
            const { setUserTarget } = $api.setters;
            close.confirmResetPasswordModal();
            setUserTarget.toResetPassword(null);
        };

        /**
         * Submit the action.
         * @param {Pick<UserResource, 'username' | 'email' | 'role'>} user
         * @param {[{id: number, name: String}]} selectedAccounts
         */
        const onSubmitAddUser = async (user, selectedAccounts) => {
            const { close, refreshUsers } = $api.methods;
            const { setLoading, setUserTarget } = $api.setters;
            const { getUserAccountsForPayload } = $api.getters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.addUserModal();

            // Clear alerts, if present.
            clearAlert('add-success');
            clearAlert('add-errors');
            clearAlert('add-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            // Attempt to submit the new user details.
            try {
                console.time(`[user::add]`);
                setLoading('loading');

                /** @type {import('@/api/users/createUser').ICreateUserRequest} */
                const body = {
                    email: user.email,
                    role: user.role,
                    user_name: user.username,
                    accounts:
                        user.role !== 'admin'
                            ? getUserAccountsForPayload(selectedAccounts)
                            : [],
                };

                /** @type {import('@/api/users').IResponseResult} */
                const result = await createUser(body);

                // If successful, refresh users.
                if (result.status === 200) {
                    // Refresh the user index, if successful. (Otherwise, nothing to reload).
                    await refreshUsers(true);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while adding a user`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `add-error`,
                        type: 'error',
                        title: `An unknown error occurred while adding a user`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[user::add]`);
                // Clear the user target.
                setUserTarget.toAdd(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
            }
        };

        /**
         * Submit the action.
         * @param {Pick<UserResource, 'username' | 'email' | 'role' | 'firstname' | 'lastname'>} user
         * @param {[{id: number, name: String}]} selectedAccounts
         */
        const onSubmitEditUser = async (user, selectedAccounts) => {
            const { close, refreshUsers } = $api.methods;
            const { setLoading, setUserTarget } = $api.setters;
            const { getUserAccountsForPayload } = $api.getters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.editUserModal();

            // Clear alerts, if present.
            clearAlert('edit-success');
            clearAlert('edit-errors');
            clearAlert('edit-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[user::edit]`);
                setLoading('loading');

                /** @type {import('@/api/users/updateUserById').IEditUserTarget} */
                const target = pick(state.userToEdit.value, 'id');

                /** @type {import('@/api/users/updateUserById').IEditUserRequest} */
                const request = {
                    email: user.email,
                    role: user.role,
                    user_name: user.username,
                    first_name: user.firstname,
                    last_name: user.lastname,
                    accounts:
                        user.role !== 'admin'
                            ? getUserAccountsForPayload(selectedAccounts)
                            : [],
                };

                /** @type {import('@/api/users').IResponseResult} */
                const result = await updateUserById(target, request);

                // If successful, refresh users.
                if (result.status === 200) {
                    // Refresh the user index, if successful. (Otherwise, nothing to reload).
                    await refreshUsers(true);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while updating the user`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `edit-error`,
                        type: 'error',
                        title: `An unknown error occurred while editing a user`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[user::edit]`);
                // Clear the user target.
                setUserTarget.toEdit(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
            }
        };

        /**
         * Submit the action.
         */
        const onSubmitDeleteUser = async () => {
            const { close, refreshUsers } = $api.methods;
            const { setLoading, setUserTarget } = $api.setters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.confirmDeleteModal();

            // Clear alerts, if present.
            clearAlert('delete-success');
            clearAlert('delete-errors');
            clearAlert('delete-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[user::delete]`);
                setLoading('loading');

                /** @type {import('@/api/users/deleteUserById').IDeleteUserTarget} */
                const target = {
                    id: state.userToDelete.value.id,
                    user_name: state.userToDelete.value.username,
                    email: state.userToDelete.value.email,
                };

                /** @type {import('@/api/users').IResponseResult} */
                const result = await deleteUserById(target);

                // If successful...
                if (result.status === 200) {
                    // Refresh the user index, if successful. (Otherwise, nothing to reload).
                    await refreshUsers(true);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while deleting the user`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `delete-error`,
                        type: 'error',
                        title: `An unknown error occurred while deleting the user`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[user::delete]`);
                // Clear the user target.
                setUserTarget.toDelete(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
            }
        };

        /**
         * Submit the action.
         */
        const onSubmitResetUserPassword = async () => {
            const { close } = $api.methods;
            const { setLoading, setUserTarget } = $api.setters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.confirmResetPasswordModal();

            // Clear alerts, if present.
            clearAlert('reset-success');
            clearAlert('reset-errors');
            clearAlert('reset-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[user::reset::password]`);
                setLoading('loading');

                /** @type {import('@/api/users/resetUserPassword').IResetPasswordTarget} */
                const target = {
                    id: state.userToResetPassword.value.id,
                    user_name: state.userToResetPassword.value.username,
                    email: state.userToResetPassword.value.email,
                };

                /** @type {import('@/api/users').IResponseResult} */
                const result = await resetUserPassword(target);

                // If successful...
                if (result.status === 200) {
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `reset-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `reset-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `reset-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while sending the reset password email`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `reset-error`,
                        type: 'error',
                        title: `An unknown error occurred while resetting a user`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[user::reset::password]`);
                // Clear the user target.
                setUserTarget.toResetPassword(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
            }
        };

        /**
         * After initialization, run this event.
         */
        const onInit = async () => {
            // Initialize the column definitions.
            const {
                getColumnDefs,
                getUserAccountsLeftColumnDefs,
                getUserAccountsRightColumnDefs,
            } = $api.getters;
            state.colDefs.value = [...getColumnDefs()];
            state.userAccountsLeftColDefs.value = [
                ...getUserAccountsLeftColumnDefs(),
            ];
            state.userAccountsRightColDefs.value = [
                ...getUserAccountsRightColumnDefs(),
            ];
        };

        const onDragAccountToRight = (item) => {
            const { setRightUserAccountsRowData } = $api.setters;
            setRightUserAccountsRowData([item]);
        };

        /** Event handlers and callbacks. */
        this.events = {
            onInit,
            onUpdateUsers,
            onUpdateAccounts,
            onDragAccountToRight,
            get onClick() {
                return {
                    addUser: onClickAddUser,
                    editUser: onClickEditUser,
                    deleteUser: onClickDeleteUser,
                    resetUserPassword: onClickResetUserPassword,
                };
            },
            get onCancel() {
                return {
                    addUser: onCancelAddUser,
                    editUser: onCancelEditUser,
                    deleteUser: onCancelDeleteUser,
                    resetUserPassword: onCancelResetUserPassword,
                };
            },
            get onSubmit() {
                return {
                    addUser: onSubmitAddUser,
                    editUser: onSubmitEditUser,
                    deleteUser: onSubmitDeleteUser,
                    resetUserPassword: onSubmitResetUserPassword,
                };
            },
        };
    }

    initMethods() {
        const $api = this;
        const { state, users, accounts } = $api.context;

        /**
         * Format the date.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const formatDate = (params) => {
            /** datetime value in the format of 'yyyy-MM-DDThh:mm:ss.sssZ' */
            const datetime = /** @type {String} */ (params.value);
            if (!isNil(datetime) && datetime !== '') {
                const [date, time] = datetime.split('T');
                return date;
            }
            return `No date provided.`;
        };

        /**
         * Update the row data after requesting account users from the cached index.
         * @param {Boolean} [forceReload]
         */
        const refreshUsers = async (forceReload = false) => {
            const { setLoading } = $api.setters;
            try {
                console.time(`[users::index] - Refreshing User index:`);
                setLoading('loading');
                // ==== REFRESH ====
                const accountUsers = await users.refreshUserIndex(forceReload);
                $api.events.onUpdateUsers(accountUsers ?? []);
                // ==== END ====
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                throw error;
            } finally {
                console.timeEnd(`[users::index] - Refreshing User index:`);
            }
        };

        /**
         * Update the row data after requesting account users from the cached index.
         * @param {Boolean} [forceReload]
         */
        const refreshAccounts = async (
            forceReload = false,
            sourceUser = {}
        ) => {
            const { setLoading } = $api.setters;
            try {
                setLoading('loading');
                // ==== REFRESH ====
                const _accounts = await accounts.refreshAccountsIndex(
                    forceReload
                );
                $api.events.onUpdateAccounts(_accounts ?? [], sourceUser);
                // ==== END ====
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                throw error;
            }
        };

        /** Open modal. */
        const openAddUserModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('addUser');
        };

        /** Open modal. */
        const openEditUserModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('editUser');
        };

        /** Open modal. */
        const openConfirmDeleteModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('confirmDelete');
        };

        /** Open modal. */
        const openConfirmResetPasswordModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('confirmResetPassword');
        };

        /**
         * Close modal if it matches.
         * @param {'addUser' | 'editUser' | 'confirmDelete' | 'confirmResetPassword'} id
         */
        const closeModalIfOpen = (id) => {
            if (state.openModal.value === id) {
                // Close modal if it matches.
                $api.setters.setOpenModal(null);
            }
        };

        /** Event triggers and methods. */
        this.methods = {
            formatDate,
            refreshUsers,
            refreshAccounts,
            get open() {
                return {
                    addUserModal: openAddUserModal,
                    editUserModal: openEditUserModal,
                    confirmDeleteModal: openConfirmDeleteModal,
                    confirmResetPasswordModal: openConfirmResetPasswordModal,
                };
            },
            get close() {
                return {
                    addUserModal: () => closeModalIfOpen('addUser'),
                    editUserModal: () => closeModalIfOpen('editUser'),
                    confirmDeleteModal: () => closeModalIfOpen('confirmDelete'),
                    confirmResetPasswordModal: () =>
                        closeModalIfOpen('confirmResetPassword'),
                };
            },
        };
    }
}

/**
 * Composable function that returns the initialized context object.
 */
export const useUserManager = () => {
    const context = new UserManager();
    return context.initialize();
};

// <!-- DEFAULT -->
export default useUserManager;
