<template>
    <div class="relative -top-2 flex flex-nowrap flex-row max-h-10">
        <BaseTooltip
            target="#tooltip-target"
            text="Edit Account"
            :textSize="'text-md'"
            :width="115"
        >
            <template #icon>
                <VariantButton
                    variant="icon"
                    alt="Edit Account"
                    @click="params.handleEdit($event, params.node.id)"
                >
                    <PencilIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
        <BaseTooltip
            target="#tooltip-target"
            text="Delete Account"
            :textSize="'text-md'"
            :width="135"
        >
            <template #icon>
                <VariantButton
                    variant="danger-icon"
                    alt="Delete Account"
                    @click="params.handleDelete($event, params.node.id)"
                >
                    <TrashIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import { PencilIcon, TrashIcon } from '@heroicons/vue/outline';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AccountManagerTableIcons',
        components: {
            PencilIcon,
            TrashIcon,
            VariantButton,
            BaseTooltip,
        },
        props: {
            params: {
                /** @type {V.PropType<AgGrid.ICellRendererParams>} */
                type: Object,
                required: true,
            },
        },
    });
</script>

<style lang="scss"></style>
